import { ref, computed, onMounted } from "vue";
import * as API from "@/API/spotCheckingConfiguration";
import moment from 'moment';
import { Moment } from 'moment';
import { message } from 'ant-design-vue';

export interface TableData {
    id: string;
    name: string;
    status: string;
    billRate: string;
    editIdentifier: boolean;
    year: string;
}

const useBillRate = () => {
    const formRef = ref()

    const year = ref<Moment>()

    const basicColData = computed(() => {
        return [
            {
                title: "Payout Round",
                dataIndex: "name"
            },
            {
                title: "Status",
                dataIndex: "status"
            },
            {
                title: "Bill Rate",
                dataIndex: "billRate",
                slots: { customRender: "billRate" },
                align: 'right'
            }
        ]
    })
    const form = ref<{ tableData: TableData[] }>({
        tableData: []
    })
    let editIndexList: number[] = []
    const getBasicTable = () => {
        const params = {
            year: year.value?.format('YYYY')
        }
        API.getBillRateList(params).then(data => {
            form.value.tableData = data
        })
    }

    const yearChange = () => {
        getBasicTable()
    }
    
    const saveCell = (callback: Function, index: number) => {
        formRef.value.validate([['tableData', index, 'billRate']]).then(() => {
            callback(false)
            editIndexList.push(index)
        }).catch(() => {
            callback(true)
        })
    };

    const saveClick = () => {
        formRef.value.validate().then(() => {
            editIndexList = Array.from(new Set(editIndexList))
            const editList: TableData[] = []
            for (let i = 0; i< editIndexList.length; i ++) {
                editList.push(form.value.tableData[editIndexList[i]])
            }
            API.setBillRate(editList).then(res => {
                if (res.code === '0') {
                    message.success('Successfully Save!')
                    form.value.tableData = []
                    editIndexList = []
                    getBasicTable()
                } else {
                    message.warning(res.message)
                }
            })
        })
    }

    const init = () => {
        year.value = moment()
        API.initBillRateList().then(data => {
            form.value.tableData = data
        })
    }
    
    onMounted(() => {
        init()
    })
    return {
        year,
        basicColData,
        formRef,
        form,
        saveCell,
        saveClick,
        yearChange,
    }
}
export default useBillRate